@import url(https://fonts.googleapis.com/css?family=Lato:100,300,400);

:root {
  --black: #000000;
  --white: #FFFFFF;
  --lightgray: #DDDDDD;
}

header {
  position: relative;
  margin: 100px 0 25px 0;
  font-size: 2.3em;
  text-align: center;
  letter-spacing: 7px;
}

body {
  font-family: 'Lato', sans-serif;
  background: var(--white);
  color: var(--black);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MuiContainer-root {
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 100vw !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
